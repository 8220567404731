import React from 'react';
import LazyImage from '@/components/basic/image';
import BankListItem from './component/bank-list-item';
import {useTranslation} from 'react-i18next';
import {CardListItemType} from './withdraw-service';
import Icons from './component/icons';
const rightIcon = require('@components/assets/icons/chevron-right.png');

export interface WithdrawBankProps {
  onAddBank?: () => void;
  onSelectBank?: () => void;
  bankInfo?: CardListItemType;
}

const WithdrawBank = (props: WithdrawBankProps) => {
  const {i18n} = useTranslation();
  const {bankInfo = {}, onAddBank = () => {}, onSelectBank = () => {}} = props;
  return (
    <div className="bg-card rounded-lg p-3 flex flex-col gap-2">
      <div className="text-t1 text-sm">
        {i18n.t('withdraw-page.label.transferTo')}
      </div>
      {bankInfo!.id ? (
        <BankListItem
          cardInfo={bankInfo}
          onItemPress={onSelectBank}
          rightIcon={
            <LazyImage
              occupancy={'transparent'}
              imageUrl={rightIcon}
              width={14}
              height={14}
            />
          }
        />
      ) : (
        <NoBank onAddBank={onAddBank} />
      )}
    </div>
  );
};

const NoBank = ({onAddBank}: {onAddBank: () => void}) => {
  const {i18n} = useTranslation();
  return (
    <div
      onClick={onAddBank}
      className="rounded-lg bg-[var(--button-small)] flex flex-col p-3 gap-2 items-center">
      <Icons.Add />
      <div className="text-[#FF8733] text-xs">
        {i18n.t('withdraw-page.label.addBank')}
      </div>
    </div>
  );
};

export default WithdrawBank;
