import React from 'react';
import {useTranslation, Trans} from 'react-i18next';
import {View} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import {TextInput} from '@/components/v2/basic/input-field';
import globalStore from '@/services/global.state';

export interface WithdrawAmountType {
  amount: string;
  receiveAmount: string;
  onAmountChange: (v: string) => void;
}

const WithdrawAmount = (props: WithdrawAmountType) => {
  const {i18n} = useTranslation();
  const {amount = '', onAmountChange, receiveAmount = ''} = props;

  return (
    <div className="rounded-lg bg-card flex flex-col gap-3 p-3">
      <div className="flex flex-col pb-3 gap-2 border-b border-line">
        <div className="text-t1 text-sm">
          {i18n.t('withdraw-page.label.withdrawAmount')}
        </div>
        <TextInput
          value={amount}
          onValueChange={value => {
            const regex = /^[0-9\b]+$/;
            if (
              value === '' ||
              (regex.test(value) &&
                value[0] !== '0' &&
                Number(value) <= 1000000)
            ) {
              onAmountChange(value);
            }
          }}
          hasMax={false}
          placeholder={i18n.t('withdraw-page.placeholder.enterAmount')}
        />
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <Text color={theme.fontColor.accent} size="medium">
            {i18n.t('withdraw-page.label.received')}
          </Text>
          <Text blod size="medium" style={[theme.margin.leftxxs]}>
            {globalStore.currency}
            {receiveAmount || '-'}
          </Text>
        </View>
      </div>
      <div className="flex flex-col gap-4 text-t2 text-xs">
        {['fee', 'times', 'note', 'limit'].map(key => (
          <div key={key}>
            <Trans
              i18nKey={`withdraw-page.rules.${key}`}
              components={{
                mark: <span className="text-t1" />,
              }}>
              {i18n.t(`withdraw-page.rules.${key}`)}
            </Trans>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WithdrawAmount;
