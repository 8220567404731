import theme from '@/style';
import React, {useEffect, useRef} from 'react';
import {
  FlatList,
  ListRenderItemInfo,
  NativeScrollEvent,
  NativeSyntheticEvent,
  TouchableOpacity,
  View,
} from 'react-native';
import {useInnerStyle} from './vip.hooks';
import {
  VipProgressInfo,
  VipRenderType,
  maxVipLevel,
  useVipProgressList,
  vipColors,
} from '@/components/business/vip';
import Text from '@basicComponents/text';
import {toPriceStr} from '@/utils';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {IVipItem, postUserInfo} from '@/services/global.service';
import globalStore from '@/services/global.state';
import {postReceiveBox} from './vip.service';
import {useTranslation} from 'react-i18next';
import VipReceivingBox from './vip-receiving-box';
import cls from './vip.module.scss';

export interface VipCardListProps {
  rechargeAmount: number;
  vipInfoList: VipProgressInfo[];
  cards: VipRenderType[];
  vipList: IVipItem[];
  onRecharge?: () => void;
  onCheck?: (index: number) => void;
  onRefresh?: () => void;
  checkIndex?: number;
  currentLevel: number;
}

const VipCardList: React.FC<VipCardListProps> = ({
  vipInfoList,
  cards,
  vipList,
  currentLevel,
  rechargeAmount,
  onRecharge,
  onCheck,
  onRefresh,
  checkIndex,
}) => {
  const {i18n} = useTranslation();
  const {
    vipStyle,
    cardStyle,
    size: {vipCardWidth},
  } = useInnerStyle();
  const ref = useRef<FlatList>(null);
  const renderProgressList = useVipProgressList(vipInfoList);
  const handleScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    const width = vipCardWidth + theme.paddingSize.s;
    const x = e.nativeEvent.contentOffset.x;
    const nowIndex = Math.round(x / width);
    onCheck?.(nowIndex);
  };
  const handleReceive = async (item: IVipItem) => {
    if (item.statusReached === 1 && item.rewardReceivingStatus === 1) {
      try {
        globalStore.globalLoading.next(true);
        const userInfo = await postUserInfo();
        await postReceiveBox(userInfo.userId, item.level);
        globalStore.globalSucessTotal(i18n.t('vip.success'));
        globalStore.updateAmount.next({});
        globalStore.doNotices.next();
        onRefresh?.();
      } finally {
        globalStore.globalLoading.next(false);
      }
    }
  };
  useEffect(() => {
    if (vipList.length <= 0) {
      return;
    }
    ref.current?.scrollToIndex({
      index: checkIndex || 0,
    });
  }, [checkIndex, vipList.length]);
  const renderVipCardItem = ({item, index}: ListRenderItemInfo<IVipItem>) => {
    const rewardReceivingStatus = vipList[index - 1]?.rewardReceivingStatus; // rewardReceivingStatus
    const boxStatusReached = vipList[index - 1]?.statusReached; //
    return (
      <View style={[theme.margin.rightm]} key={index}>
        {cards[index].backgroundFn(
          <View style={[theme.position.rel, theme.flex.col]}>
            <View style={[theme.margin.topxxl, theme.padding.leftm]}>
              <View
                style={[
                  theme.margin.leftm,
                  theme.flex.row,
                  theme.flex.centerByCol,
                ]}>
                <Text
                  fontSize={32}
                  fontFamily="fontDin"
                  blod
                  color={vipColors[index]}>
                  V{index}
                </Text>
                {index !== 0 && (
                  <View
                    style={[
                      cardStyle.topTag,
                      theme.padding.lrs,
                      theme.padding.tbxxs,
                      theme.margin.lefts,
                    ]}>
                    <Text fontSize={theme.fontSize.s} accent>
                      {vipList[index - 1].statusReached === 1
                        ? vipList[index - 1].rewardReceivingStatus === 0
                          ? i18n.t('vip.received')
                          : i18n.t('vip.collected')
                        : i18n.t('vip.finished')}
                    </Text>
                  </View>
                )}
              </View>
            </View>
            <View style={[theme.position.abs, cardStyle.sign]}>
              {cards[index].sign('large')}
            </View>
            <View
              style={[
                theme.position.rel,
                theme.margin.tops,
                theme.padding.tbs,
                cardStyle.progressTextContainer,
                theme.flex.row,
              ]}>
              <Text color="#000" main fontSize={theme.fontSize.m} blod>
                {toPriceStr(rechargeAmount, {fixed: 0, thousands: true})}
              </Text>
              <Text
                accent
                fontSize={theme.fontSize.m}
                color="rgba(0,0,0,0.5)"
                blod
                style={[theme.margin.leftxxs]}>
                / {toPriceStr(item.amount, {fixed: 0, thousands: true})}(V
                {Math.min(index + 1, maxVipLevel)})
              </Text>
              {index !== 0 && (
                <View style={[theme.position.abs, cardStyle.box]}>
                  <NativeTouchableOpacity
                    activeOpacity={
                      vipList[index - 1].rewardReceivingStatus === 1 &&
                      vipList[index - 1].statusReached === 1
                        ? 0.8
                        : 1
                    }
                    onPress={() => handleReceive(vipList[index - 1])}>
                    <div className={cls.box}>
                      <VipReceivingBox
                        statusReached={vipList[index - 1].statusReached}
                        rewardReceivingStatus={
                          vipList[index - 1].rewardReceivingStatus
                        }
                      />
                      {/*Claimed*/}
                      <div
                        className={`${cls.handler} ${
                          !rewardReceivingStatus || !boxStatusReached
                            ? cls.over
                            : ''
                        }`}>
                        {boxStatusReached ? 'Claimed' : 'Not achieved'}
                      </div>
                    </div>
                  </NativeTouchableOpacity>
                </View>
              )}
            </View>
            <View
              style={[theme.padding.lrm, theme.flex.col, theme.margin.tops]}>
              <View style={[theme.flex.row]}>{renderProgressList[index]}</View>
              <View
                style={[
                  theme.flex.row,
                  theme.flex.between,
                  theme.flex.centerByCol,
                ]}>
                <View
                  style={[
                    theme.flex.row,
                    theme.flex.centerByCol,
                    theme.margin.lrm,
                    cardStyle.notice,
                  ]}>
                  <Text
                    numberOfLines={2}
                    main
                    color="rgba(0,0,0,1)"
                    style={[
                      cardStyle.opacity,
                      item.level >= currentLevel ? cardStyle.text : [],
                    ]}
                    fontSize={theme.fontSize.xs}>
                    {i18n.t('vip.recharge')}{' '}
                    <Text blod color="rgba(0,0,0,1)">
                      {toPriceStr(item.diff, {fixed: 0, thousands: true})}
                    </Text>{' '}
                    {i18n.t('vip.move')} VIP
                    {Math.min(item.level + 1, maxVipLevel)}
                  </Text>
                </View>
                {item.level >= currentLevel && (
                  <TouchableOpacity activeOpacity={0.8}>
                    <div className={cls.btn} onClick={onRecharge}>
                      <Text color="#fff" fontSize={10} blod>
                        {i18n.t('vip.recharge')}
                      </Text>
                    </div>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </View>,
          vipCardWidth,
        )}
      </View>
    );
  };
  return (
    <FlatList
      ref={ref}
      style={[vipStyle.cardList]}
      showsHorizontalScrollIndicator={false}
      onScroll={handleScroll}
      contentContainerStyle={[vipStyle.cardListContent, theme.flex.alignEnd]}
      data={vipList}
      initialScrollIndex={currentLevel}
      pagingEnabled
      snapToAlignment="center"
      renderItem={renderVipCardItem}
      getItemLayout={(data, index) => ({
        length: vipCardWidth + theme.paddingSize.s,
        offset: (vipCardWidth + theme.paddingSize.s) * index,
        index,
      })}
      horizontal
    />
  );
};

export default VipCardList;
