// import LazyImage from '@/components/basic/image';
// const rightIcon = require('@components/assets/icons/chevron-right.png');
import globalStore from '@/services/global.state';
import {toPriceStr} from '@/utils';
import React from 'react';
import {StyleSheet, Image} from 'react-native';
import {useTranslation} from 'react-i18next';
// import {useScreenSize} from '../hooks/size.hooks';
import DarkRefresh from '../svg/dark-refresh';

const TransferBalance = ({
  balance = 0,
  onRefresh,
}: // onGotoRecords,
{
  balance: number;
  onRefresh?: () => void;
  // onGotoRecords?: () => void;
}) => {
  const {i18n} = useTranslation();

  // const {screenWidth, screenHeight, designWidth} = useScreenSize();
  // const rightIconSize = (14 * screenWidth) / designWidth;
  // const itemWidth = (72 * screenWidth) / designWidth;
  // const itemHeight = (40 * screenWidth) / designWidth;
  // const size = React.useMemo(() => {
  //   return {
  //     screenWidth,
  //     screenHeight,
  //     designWidth,
  //     rightIconSize,
  //     itemWidth,
  //     itemHeight,
  //   };
  // }, [
  //   screenWidth,
  //   screenHeight,
  //   designWidth,
  //   rightIconSize,
  //   itemWidth,
  //   itemHeight,
  // ]);

  return (
    <div className="relative rounded-lg px-5 py-[1.375rem] overflow-hidden">
      <div className="absolute top-0 right-0 left-0 bottom-0">
        <Image
          resizeMode="cover"
          source={require('@assets/imgs/recharge-header-bg.webp')}
          style={[styles.bgBox]}
        />
      </div>
      <div className="flex flex-row items-center justify-between relative z-1">
        <div className="flex-1">
          <div className="text-t1 text-xs">
            {i18n.t('transfer-page.label.balance')}
          </div>
          <div className="flex flex-row items-end">
            <div className="text-t1 font-din text-2xl font-bold">
              {toPriceStr(balance, {
                thousands: true,
                spacing: true,
                currency: globalStore.currency,
              })}
            </div>
          </div>
        </div>
        <div onClick={onRefresh} className="ml-[0.625rem]">
          <DarkRefresh />
        </div>
        {/* <div
          className="flex flex-row items-center gap-2"
          onClick={onGotoRecords}
          >
          <div className="flex flex-col items-end">
            <div className="text-t1 text-xs">{i18n.t('label.transfer')}</div>
            <div className="text-t1 text-xs">
              {i18n.t('recharge-page.label.records')}
            </div>
          </div>
          <LazyImage
            occupancy={'transparent'}
            imageUrl={rightIcon}
            width={size.rightIconSize}
            height={size.rightIconSize}
          />
        </div> */}
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  opacity: {
    opacity: 0.7,
  },
  bgBox: {
    width: '100%',
    height: '100%',
  },
  balanceContainer: {
    paddingHorizontal: 8,
    // paddingTop: 20,
    alignItems: 'center',
  },
  methodContainer: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    // paddingHorizontal: 20,
  },
});

export default TransferBalance;
