import {useTranslation} from 'react-i18next';
import LazyImage from '@/components/basic/image';
import {View, Image, StyleSheet} from 'react-native';
import React from 'react';
import theme from '@/style';
import {toPriceStr} from '@/utils';
import DarkRefresh from '../svg/dark-refresh';
import TransferIcon from '../svg/transfer';
import globalStore from '@/services/global.state';
import LinearGradient from '@/components/basic/linear-gradient';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {useScreenSize} from '../hooks/size.hooks';
const rightIcon = require('@components/assets/icons/chevron-right.png');

export interface WithdrawBalanceProps {
  balance?: number;
  onRefresh?: () => void;
  onPressTransfer?: () => void;
  // onGotoRecords?: () => void;
}

const WithdrawBalance: React.FC<WithdrawBalanceProps> = props => {
  const {balance = 0, onRefresh, onPressTransfer} = props;
  const {i18n} = useTranslation();

  const {screenWidth, screenHeight, designWidth} = useScreenSize();
  const rightIconSize = (14 * screenWidth) / designWidth;
  const itemWidth = (72 * screenWidth) / designWidth;
  const itemHeight = (40 * screenWidth) / designWidth;
  const size = React.useMemo(() => {
    return {
      screenWidth,
      screenHeight,
      designWidth,
      rightIconSize,
      itemWidth,
      itemHeight,
    };
  }, [
    screenWidth,
    screenHeight,
    designWidth,
    rightIconSize,
    itemWidth,
    itemHeight,
  ]);

  return (
    <View style={[theme.margin.topl, styles.container]}>
      <View style={styles.cardBg}>
        <Image
          resizeMode="cover"
          source={require('@assets/imgs/recharge-header-bg.webp')}
          style={[styles.bgBox]}
        />
      </View>
      <View
        style={[styles.balanceContainer, theme.flex.row, theme.flex.between]}>
        <View style={[theme.flex.flex1]}>
          <div className="text-t1 text-xs opacity-70">
            {i18n.t('label.balance')}
          </div>
          <View style={[theme.flex.row, theme.flex.alignEnd]}>
            <div className="text-t1 font-din text-2xl font-bold">
              {toPriceStr(balance, {
                thousands: true,
                spacing: true,
                currency: globalStore.currency,
              })}
            </div>
            {/* <NativeTouchableOpacity
              activeOpacity={0.8}
              onPress={onRefresh}
              style={[theme.margin.leftm]}>
              <DarkRefresh />
            </NativeTouchableOpacity> */}
          </View>
        </View>
        <NativeTouchableOpacity
          activeOpacity={0.8}
          onPress={onRefresh}
          style={[theme.margin.leftm]}>
          <DarkRefresh />
        </NativeTouchableOpacity>
        {/* <NativeTouchableOpacity
          activeOpacity={0.8}
          style={[theme.flex.row, theme.flex.centerByCol]}
          onPress={onGotoRecords}>
          <div className="flex flex-col items-end mr-2">
            <span className="text-t1 text-xs">
              {i18n.t('label.withdrawal')}
            </span>
            <span className="text-t1 text-xs">
              {i18n.t('recharge-page.label.records')}
            </span>
          </div>
          <LazyImage
            occupancy={'transparent'}
            imageUrl={rightIcon}
            width={size.rightIconSize}
            height={size.rightIconSize}
          />
        </NativeTouchableOpacity> */}
      </View>
      <NativeTouchableOpacity
        style={[theme.margin.tops]}
        onPress={onPressTransfer}>
        <LinearGradient
          style={[
            styles.bottomContainer,
            theme.flex.row,
            theme.flex.centerByCol,
          ]}
          start={{x: 0, y: 0}}
          end={{x: 0, y: 1}}
          colors={['rgba(0,0,0,0.3)', 'rgba(0,0,0,0)']}>
          <View
            style={[theme.flex.row, theme.flex.centerByCol, theme.flex.flex1]}>
            <TransferIcon />
            <View style={[theme.margin.lefts]}>
              <div className="text-t1 text-xs font-bold">
                {i18n.t('label.transfer')}
              </div>
              <div className="text-t1 text-xs w-[16rem]">
                {i18n.t('withdraw-page.tip.transfer')}
              </div>
            </View>
          </View>
          <LazyImage
            occupancy={'transparent'}
            imageUrl={rightIcon}
            width={size.rightIconSize}
            height={size.rightIconSize}
          />
        </LinearGradient>
      </NativeTouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  cardBg: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  container: {
    position: 'relative',
    borderRadius: 8,
    padding: 12,
    overflow: 'hidden',
  },
  bottomContainer: {
    borderRadius: 8,
    padding: 8,
    overflow: 'hidden',
  },
  opacity: {
    opacity: 0.7,
  },
  bgBox: {
    width: '100%',
    height: '100%',
  },
  balanceContainer: {
    paddingHorizontal: 8,
    // paddingTop: 20,
    alignItems: 'center',
  },
  methodContainer: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    // paddingHorizontal: 20,
  },
});

export default React.memo(WithdrawBalance);
