import theme from '@/style';
import React, {CSSProperties} from 'react';
import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {CardListItemType} from '../withdraw-service';
import LazyImage from '@/components/basic/image';
import EditIcon from '../../svg/edit';
import {formatNumberGroup} from '@/components/utils';
const whiteCheck = require('@/assets/icons/withdraw/white-check.png');
const defaultCard = require('@/assets/icons/withdraw/default-card-icon.webp');
export interface BankListItemType {
  style?: CSSProperties;
  backgroundColor?: string;
  selectMode?: boolean;
  isSelect?: boolean;
  onItemPress?: (cardId?: string) => void;
  onEditBank?: () => void;
  cardInfo?: CardListItemType;
  rightIcon?: null | React.ReactElement<{}>;
}

const BankListItem = (props: BankListItemType) => {
  const {
    style = {},
    rightIcon,
    backgroundColor = '#017CC1',
    onItemPress = () => {},
    onEditBank,
    cardInfo = {},
    selectMode = false,
    isSelect = false,
  } = props;

  return (
    <div
      onClick={() => onItemPress(cardInfo!.id)}
      style={{
        boxShadow: '0px 2px 0px 0px rgba(255, 255, 255, 0.25) inset',
        backgroundColor,
        ...style,
      }}
      className={`relative flex flex-row items-center px-3 py-4 rounded-lg border ${
        isSelect ? 'border-[#FF6900]' : 'border-[rgba(0,0,0,0)]'
      }`}>
      <div className="flex flex-row flex-1 gap-4 items-center">
        <LazyImage
          occupancy="transparent"
          width={40}
          height={40}
          imageUrl={cardInfo.img || defaultCard}
        />
        <div className="flex flex-col gap-1">
          <div className="text-t1 text-sm">{cardInfo!.accountName}</div>
          <div className="text-t2 text-xs font-normal">
            {formatNumberGroup(cardInfo!.accountNumber)}
          </div>
        </div>
      </div>
      {rightIcon}
      {selectMode && <Edit onEditBank={onEditBank} />}
      {selectMode && isSelect && <SelectedItem />}
    </div>
  );
};

const Edit = (props: {onEditBank?: () => void}) => {
  const {onEditBank = () => {}} = props;
  return (
    <NativeTouchableOpacity
      onPress={() => onEditBank()}
      style={[
        theme.background.white,
        theme.borderRadius.xs,
        styles.editButton,
      ]}>
      <EditIcon />
      <Text color={theme.basicColor.primary} style={[theme.margin.leftxxs]}>
        Edit
      </Text>
    </NativeTouchableOpacity>
  );
};

const SelectedItem = () => {
  return (
    <View style={styles.circle}>
      <View style={styles.check}>
        <LazyImage
          occupancy="transparent"
          imageUrl={whiteCheck}
          width={12}
          height={12}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  editButton: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 20,
    position: 'absolute',
    top: 8,
    right: 12,
    paddingHorizontal: 4,
  },
  circle: {
    position: 'absolute',
    width: 48,
    height: 48,
    right: -24,
    bottom: -24,
    borderRadius: 24,
    backgroundColor: theme.basicColor.primary,
  },
  check: {
    position: 'absolute',
    top: 8,
    left: 8,
  },
});

export default BankListItem;
