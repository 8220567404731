import {toPriceStr} from '@/utils';
import globalStore from '@/services/global.state';
import React from 'react';
import {useTranslation} from 'react-i18next';

export interface HeaderType {
  amount?: number | 0;
}

const Header = (props: HeaderType) => {
  const {amount = 0} = props;
  const {i18n} = useTranslation();
  return (
    <div className="bg-card h-[5.5rem] flex flex-col items-center justify-center py-[1.375rem] px-3 border-b border-line">
      <div className="text-t3 text-xs">
        {i18n.t('transfer-page.label.total')}
      </div>
      <div className="text-t1 font-din text-2xl font-bold">
        {toPriceStr(amount, {
          thousands: true,
          fixed: 2,
          spacing: true,
          currency: globalStore.currency,
        })}
      </div>
    </div>
  );
};

export default Header;
