import React from 'react';
import Text from '@/components/basic/text';
import theme from '@/style';
import {useTranslation} from 'react-i18next';
import LazyImage from '@/components/basic/image';
import Add from '../svg/add';
import BankListItem from './component/bank-list-item';
import {CardListItemType} from './withdraw-service';
const closeIcon = require('@/assets/icons/close.webp');

export interface SelectCardsType {
  list: CardListItemType[];
  value?: string;
  onChange: (v?: string) => void;
  onClose?: () => void;
  onAddBank?: (card?: CardListItemType) => void;
}

const SelectCards = (props: SelectCardsType) => {
  const {i18n} = useTranslation();
  const {
    list = [],
    onClose = () => {},
    value = '',
    onChange = () => {},
    onAddBank = () => {},
  } = props;

  return (
    <div className="max-h-[60vh] bg-card p-3 rounded-ss-lg overflow-hidden">
      <div className="h-[4rem] flex flex-row items-center justify-between py-5 px-2">
        <div className="text-t1 text-sm font-bold">
          {i18n.t('withdraw-page.label.chooseCard')}
        </div>
        <div onClick={onClose}>
          <LazyImage
            imageUrl={closeIcon}
            width={theme.iconSize.l}
            height={theme.iconSize.l}
            occupancy={'transparent'}
          />
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <div className="max-h-[14rem] overflow-auto">
          <div className="flex flex-col gap-2">
            {list.map((item, index) => {
              const isSelected = value === item.id;
              return (
                <BankListItem
                  onEditBank={() => onAddBank(item)}
                  cardInfo={item}
                  key={index}
                  selectMode
                  isSelect={item.id === value}
                  rightIcon={isSelected ? null : null}
                  onItemPress={() => onChange(item!.id)}
                  style={{
                    overflow: 'hidden',
                  }}
                />
              );
            })}
          </div>
        </div>
        <div
          onClick={() => onAddBank()}
          className="h-[4.5rem] p-3 flex flex-col items-center gap-2 rounded-lg bg-[var(--button-small)]">
          <Add />
          <Text color={theme.basicColor.primary}>
            {i18n.t('withdraw-page.label.addBank')}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default SelectCards;
