import {View, StyleSheet, Dimensions} from 'react-native';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {TextInput} from '@/components/basic/input-field';
import Text from '@/components/basic/text';
import theme from '@/style';

import LazyImage from '@/components/basic/image';
import React from 'react';
import {useTranslation} from 'react-i18next';
import RechargeButton from '@/components/business/recharge-button';
import globalStore from '@/services/global.state';
import {toPriceStr} from '@/utils';
const closeIcon = require('@/assets/icons/close.webp');
const {width} = Dimensions.get('window');

const WithdrawTransfer = (props: {
  withdrawAmount: number;
  inputAmount: string;
  receiveAmount: number;
  onInputChange: (v: string) => void;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const {
    withdrawAmount = 0,
    onInputChange = () => {},
    onClose = () => {},
    inputAmount = '',
    receiveAmount = 0,
    onConfirm,
  } = props;
  const {i18n} = useTranslation();

  const disabled = React.useMemo(() => {
    if (!withdrawAmount) {
      return true;
    } else {
      if (parseInt(inputAmount, 10) >= Math.trunc(withdrawAmount)) {
        return true;
      }
    }
    return false;
  }, [inputAmount, withdrawAmount]);

  return (
    <div className="w-full rounded-ss-lg flex flex-col bg-card">
      <div className="p-5 flex flex-row items-center justify-between">
        <div className="text-t1 text-sm font-bold">
          {i18n.t('label.transfer')}
        </div>
        <div onClick={onClose}>
          <LazyImage
            imageUrl={closeIcon}
            width={theme.iconSize.m}
            height={theme.iconSize.m}
            occupancy={'transparent'}
          />
        </div>
      </div>
      <View style={[styles.body]}>
        <TextInput
          hasMax
          placeholder={`${i18n.t(
            'transfer-page.label.withdrawable',
          )}: ${toPriceStr(withdrawAmount, {
            thousands: true,
            spacing: false,
            currency: globalStore.currency,
          })}`}
          rightElement={
            <NativeTouchableOpacity
              onPress={() => onInputChange(`${Math.trunc(withdrawAmount)}`)}
              disabled={disabled}>
              <Text
                size="medium"
                blod
                color={theme.basicColor.primary}
                style={[disabled && styles.disabled]}>
                MAX
              </Text>
            </NativeTouchableOpacity>
          }
          onValueChange={value => {
            const regex = /^[0-9\b]+$/;
            if (value === '' || (regex.test(value) && value[0] !== '0')) {
              onInputChange(value);
            }
          }}
          value={inputAmount}
        />
        <View style={[theme.flex.row, styles.buttonContainer]}>
          {[100, 200, 500, 1000].map((item, index) => (
            <div
              onClick={() => onInputChange(`${item}`)}
              style={{
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
              }}
              className="flex items-center justify-center bg-[var(--button-small)] rounded h-10 w-[4.75rem]"
              key={index}>
              <Text main blod fontSize={theme.fontSize.m}>
                {toPriceStr(item, {
                  fixed: 0,
                  showCurrency: false,
                  thousands: true,
                })}
              </Text>
            </div>
          ))}
        </View>
        <Text adjustsFontSizeToFit color={theme.fontColor.accent}>
          {i18n.t('transfer-page.tip.tip')}
        </Text>
      </View>
      <View
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          theme.flex.center,
          theme.margin.tops,
        ]}>
        <Text>{i18n.t('transfer-page.label.willGet')}:</Text>
        <Text style={styles.money} fontFamily="fontInterBold" size="large">
          {toPriceStr(receiveAmount, {
            showCurrency: true,
            thousands: true,
          })}
        </Text>
      </View>
      <RechargeButton
        disabled={Boolean(!receiveAmount)}
        onRecharge={onConfirm}
        type="linear-primary"
        text={i18n.t('label.confirm')}
        background={{
          backgroundColor: 'var(--card)',
        }}
      />
    </div>
  );
};

const styles = StyleSheet.create({
  header: {
    height: 44,
    paddingHorizontal: 20,
    marginBottom: 20,
  },
  body: {
    marginHorizontal: 24,
    paddingBottom: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#353942',
  },
  money: {
    marginLeft: 4,
    fontSize: 20,
    color: theme.basicColor.primary,
  },
  buttonContainer: {
    columnGap: 13,
    marginTop: 8,
    marginBottom: 16,
  },
  buttonItem: {
    borderColor: 'white',
    borderWidth: 1,
    elevation: 2,
    width: (width - 48 - 13 * 3) / 4,
    height: 40,
  },
  disabled: {
    opacity: 0.3,
  },
});

export default WithdrawTransfer;
