import React from 'react';
import {StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
import {toPriceStr} from '@/utils';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {TextInput} from '@/components/v2/basic/input-field';

const TransferAmount = ({
  // receiveAmount = 0,
  inputAmount,
  onInputChange,
  balance = 0,
}: {
  // receiveAmount: number;
  inputAmount: string;
  balance: number;
  onInputChange: (v: string) => void;
}) => {
  const {i18n} = useTranslation();

  const disabled = React.useMemo(() => {
    if (!balance) {
      return true;
    } else {
      if (parseInt(inputAmount, 10) >= Math.trunc(balance)) {
        return true;
      }
    }
    return false;
  }, [inputAmount, balance]);

  return (
    <div className="bg-card rounded-lg flex flex-col p-3 gap-2">
      <TextInput
        onValueChange={value => {
          const regex = /^[0-9\b]+$/;
          if (value === '' || (regex.test(value) && value[0] !== '0')) {
            onInputChange(value);
          }
        }}
        value={inputAmount}
        hasMax
        placeholder={`${i18n.t(
          'transfer-page.label.withdrawable',
        )}: ${toPriceStr(balance, {
          thousands: true,
          fixed: 2,
          spacing: false,
          currency: globalStore.currency,
        })}`}
        rightElement={
          <NativeTouchableOpacity
            onPress={() => onInputChange(`${Math.trunc(balance)}`)}
            disabled={disabled}>
            <Text
              size="medium"
              blod
              color={theme.basicColor.primary}
              style={[disabled && styles.disabled]}>
              MAX
            </Text>
          </NativeTouchableOpacity>
        }
      />
      {/* <View style={[theme.flex.row, theme.flex.centerByCol, theme.margin.btms]}>
        <Text color={theme.fontColor.accent} size="medium">
          {i18n.t('withdraw-page.label.received')}
        </Text>
        <Text blod size="medium" style={[theme.margin.leftxxs]}>
          {toPriceStr(receiveAmount, {
            thousands: true,
            spacing: false,
            fixed: 2,
            currency: globalStore.currency,
          })}
        </Text>
      </View> */}
      {/* <View style={styles.line} /> */}
      <div className="flex flex-row justify-between py-2">
        {[100, 200, 500, 1000].map((item, index) => (
          <div
            onClick={() => onInputChange(`${item}`)}
            className="h-10 w-[4.75rem] flex items-center justify-center rounded border border-line bg-[var(--button-small)]"
            style={{
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            }}
            key={index}>
            <div className="text-t1 text-sm font-bold">
              {toPriceStr(item, {
                fixed: 2,
                showCurrency: false,
                thousands: true,
              })}
            </div>
          </div>
        ))}
      </div>
      <div className="text-t3 text-xs">{i18n.t('transfer-page.tip.tip')}</div>
    </div>
  );
};

const styles = StyleSheet.create({
  line: {
    height: 1,
    marginBottom: 8,
    ...theme.background.grey,
  },
  buttonContainer: {
    columnGap: 13,
    marginTop: 8,
    marginBottom: 16,
  },
  buttonItem: {
    borderColor: 'white',
    borderWidth: 1,
    elevation: 2,
    height: 40,
  },
  disabled: {
    opacity: 0.3,
  },
});

export default TransferAmount;
